<template>
	<div>
		<Hero image="../img/9.jpg" />
		<main>
			<div class="container">
				<Heading title="Board of Directors" desc="The central governing body of the school" />
				<div class="content">
					<div class="row">
						<div class="col-md-8">
							<Board />
						</div>
						<div class="col-md-4">
							<MenuAbout />
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	components: {
		Board: () => import("@/components/AboutUs/Board")
	},
};
</script>
